.products h2 {
  color: white;
  margin: 2rem auto;
  text-align: center;
  text-transform: uppercase;
}

.products ul {
  list-style: none;
  margin: 0;
  padding: 0;
}